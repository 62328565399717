<template>
    <div>
        使用说明
    </div>
</template>

<script lang="ts">
import { defineComponent, ref, reactive } from 'vue';
export default defineComponent({
    components: {

    },
    setup () {

        return {


        };
    },

});
</script>


<style scoped>
</style>